import * as React from 'react';
import {BackArrowIcon} from './Icons';
import {Link} from "gatsby";

interface IBackButton {
    className: string;
    label?: string;
    prependArrow?: boolean;
}

const BackButton = ({
    className, label='Zurück', prependArrow=false,
}: IBackButton) => {
    const href = '/';
    return <Link to={href} className={className}>
        {
            prependArrow &&
            <BackArrowIcon width={50} />
        }
        {label}
    </Link>;

};

export default BackButton;
