import * as React from "react"
import Layout from "../components/Layout";
import {useEffect} from "react";
import BackButton from "../components/BackButton";

const MythNodePage = ({pageContext, data}) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://cdn.datenschutz.burda.com/4051.js';
        document.head.appendChild(script)
    }, []);

    return <Layout>
        <article>
            <BackButton className={'back-link'} prependArrow={true} />

            <noscript>
                <iframe id="hbm-privacy-iframe" src="https://cdn.datenschutz.burda.com/4051.html" />
            </noscript>

            <div id="hbm-privacy__wrapper">
                <div id="hbm-privacy"/>
            </div>
        </article>
    </Layout>
}
export default MythNodePage;

